import { Message } from 'ai'
import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

/**
 * Combines multiple class values into a single string, merging Tailwind classes efficiently.
 *
 * This function uses clsx to combine class values and tailwind-merge to handle
 * Tailwind-specific class merging.
 *
 * @param {...ClassValue[]} inputs - Any number of class values (strings, objects, or arrays).
 * @returns {string} A merged string of class names.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * Checks if a given string is a valid URL.
 *
 * This function attempts to create a new URL object with the given string. If successful, it's
 * considered a valid URL.
 *
 * @param {string} [url] - The string to check for URL validity.
 * @returns {boolean} True if the string is a valid URL, false otherwise.
 */
export function isValidUrl(url?: string): boolean {
  if (!url) return false
  try {
    new URL(url)
    return true
  } catch (_) {
    return false
  }
}

/**
 * Processes conversation history for LLM context by:
 *
 * 1. Filtering to relevant message types (user, assistant, system)
 * 2. Mapping role names to human-readable formats (e.g., 'user' -> 'Human')
 * 3. Truncating long AI responses to MAX_AI_RESPONSE_LENGTH characters
 * 4. Limiting to last MAX_MESSAGES messages
 * 5. Converting to a formatted string with role prefixes and double-line spacing
 *
 * @param messages - Array of conversation messages to process
 * @param maxMessages - Maximum number of recent messages to include (default: 5)
 * @returns Formatted string of processed conversation history
 */
export function summarizeConversation(messages: Message[], maxMessages: number = 5): string {
  const MAX_AI_RESPONSE_LENGTH = 500 // Characters

  // Define role mapping once
  const roleMap = {
    user: 'Human',
    assistant: 'AI',
    system: 'System'
  } as const

  // Filter to only relevant messages and map roles to preferred prefixes
  const filteredMessages = messages
    .filter(message => message.role in roleMap)
    .map(message => ({
      ...message,
      role: roleMap[message.role as keyof typeof roleMap]
    }))

  // Truncate AI responses
  const truncatedMessages = filteredMessages.map(message => {
    if (message.role === 'AI' && message.content.length > MAX_AI_RESPONSE_LENGTH) {
      return {
        ...message,
        content: `${message.content.slice(0, MAX_AI_RESPONSE_LENGTH)}... [truncated]`
      }
    }
    return message
  })

  // Take last N messages
  const recentMessages = truncatedMessages.slice(-maxMessages)

  // Convert to string format
  return recentMessages.map(m => `${m.role}: ${m.content}`).join('\n\n')
}
